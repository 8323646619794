@import './recommendation/component/color.less';
@font-face {
  font-family: 'Samsung Sharp Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Regular'),
    url('font/samsungsharpsans.woff') format('woff');
}

@font-face {
  font-family: 'Samsung Sharp Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Medium'),
    url('font/samsungsharpsans-medium.woff') format('woff');
}

@font-face {
  font-family: 'Samsung Sharp Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Bold'),
    url('font/samsungsharpsans-bold.woff') format('woff');
}

@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: normal;
  src: local('SamsungOne'), url('font/SamsungOne-400.woff') format('woff');
}

@font-face {
  font-family: 'SamsungOne Bold';
  font-style: normal;
  font-weight: normal;
  src: local('SamsungOne'), url('font/SamsungOne-700.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Regular';
  src: url(font/Roboto-Regular_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  src: url(font/Roboto-Light_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  src: url(font/Roboto-Medium_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: url(font/Roboto-Bold_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed Bold';
  src: url(font/RobotoCondensed-Bold_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed Light';
  src: url(font/RobotoCondensed-Light_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed Regular';
  src: url(font/RobotoCondensed-Regular_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Inter Regular'), url('font/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'SEC Regular';
  src: url(font/SEC-400Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SEC Semi Bold';
  src: url(font/SEC-600SemiBold.ttf) format('truetype');
}

body,
body > #root {
  font-family: 'Roboto Regular';
}

.ant-input {
  border: 1px solid #767b9b;
  border-radius: 6px;
}
// .ant-input:not(.ant-form-item-has-error):hover {
// //   border-color: #767B9B !important;
// //   outline: 0;
//   -webkit-box-shadow: 0 0 0 3px rgba(87,168,233, .2);
//   box-shadow: 0 0 0 3px rgba(87,168,233, .2);
// }
.ant-spin-dot-item {
  background-color: #0a186e;
}
.ant-spin-text {
  color: #0a186e;
}
.ant-select:not(.ant-select-disabled) {
  .ant-select-selector {
    border: 1px solid #767b9b;
  }
  .ant-select-selector:hover:not(.ant-form-item-has-error) {
    // border-color: #767B9B;
    // outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }
}

.registration-field-container {
  .ant-collapse-content-box {
    padding: 16px 32px;
  }
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px 10px;
  overflow-wrap: break-word;
  word-break: break-word;
}

.ant-table-thead > tr > th {
  color: #010400;
  background: #f2f2f2 !important;
  font-weight: bold !important;
}
.ant-table-thead {
  .ant-table-cell::before {
    display: none;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.table-row-light {
  background: #ffffff !important;
  .ant-table-cell-fix-left {
    background: #ffffff;
  }
}

.table-row-dark {
  background: #f2f2f2 !important;
  .ant-table-cell-fix-left {
    background: #f2f2f2 !important;
  }
  .ant-table-column-sort {
    background: #f2f2f2 !important;
  }
}

.table-row-preview {
  background: #e6f7ff !important;
  .ant-table-cell-fix-left {
    background: #e6f7ff;
  }
}

.ant-table-row-selected.table-row-light {
  .ant-table-cell.ant-table-row-expand-icon-cell.ant-table-cell-fix-left {
    background: #e6f7ff !important;
  }
}

.ant-table-row-selected.table-row-dark {
  .ant-table-cell.ant-table-row-expand-icon-cell.ant-table-cell-fix-left {
    background: #e6f7ff !important;
  }
}

.table-row-active {
  background: rgba(11, 2, 113, 0.1) !important;
  .ant-table-cell-fix-left {
    background: rgba(11, 2, 113, 0.1);
  }
}

.table-row-light:hover td {
  background: #eef7ff !important;
}

.table-row-dark:hover td {
  background: #eaf3fb !important;
}

.table-row-active:hover td {
  background: rgba(11, 2, 113, 0.15) !important;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #0a186e !important;
  border-color: #0a186e !important;
}

.ant-radio-checked {
  .ant-radio-inner {
    border-color: #0a186e !important;
  }
  .ant-radio-inner::after {
    background-color: #0a186e !important;
  }
}

.ant-menu-selected::after {
  border-right: none !important;
}

.registration-form-card {
  background: #fff;
  border: 1px solid #979797;
  border-radius: 10px;
  padding: 14px 50px;
  margin: 20px;
}

.service-card-container {
  background: #fff;
  border: 1px solid #979797;
  border-radius: 10px;
  padding: 20px 40px;
  margin-bottom: 20px;
}

.registration-form-divider {
  border: 1px solid #979797;
}

.input-country-filter {
  border-radius: 10px;
}

.country-list-outer {
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #767b9b;
}

.country-list-container {
  height: 300px;
  overflow-y: auto;
  // border: 1px solid #979797;
  padding: 10px;
  scrollbar-width: thin;
  border-radius: 5px;

  .close-icon {
    position: relative;
    // margin-right: 5px;
  }
}

.side-nav-app {
  background: #0a186e;
  position: fixed;
  width: 60px;
  border-radius: 0 20px 20px 0;
  z-index: 30;
}

.app-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.app-item-container {
  margin-top: 10px;
  padding-top: 10px;
}

.app-item-wrapper {
  height: 80px;
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  .nav-app-icon {
    fill: #ffffff;
    height: 30px;
    width: 30px;
    margin: auto;
    display: block;
    margin-bottom: 4px;
    path {
      fill: #ffffff;
    }
  }
  .svg-stroke {
    fill: #ffffff !important;
  }
}
.app-item-wrapper-selected {
  height: 80px;
  color: #0a186e;
  background-color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  .nav-app-icon {
    fill: #2b75e0;
    height: 30px;
    width: 30px;
    margin: auto;
    display: block;
    margin-bottom: 4px;
    path {
      fill: #2b75e0 !important;
    }
  }
  .svg-stroke {
    fill: #2b75e0 !important;
  }
  .nav-item-text {
    color: #2b75e0;
  }
}
.app-item-wrapper:hover {
  color: #ffffff;
  background-color: #454c7a;
  // border-radius: 8px;
  .nav-app-icon {
    fill: #ffffff;
    height: 30px;
    width: 30px;
    margin: auto;
    display: block;
    margin-bottom: 4px;
    path {
      fill: #ffffff !important;
    }
  }
  .svg-stroke {
    fill: #ffffff !important;
  }
}
.nav-item-text {
  font-size: 12px;
  font-family: 'Roboto Regular';
  font-weight: 500;
}

.side-nav-menu {
  margin-left: 60px;
  position: relative;
}

.side-nav-sider {
  position: fixed !important;
  left: 60px !important;
  background: #ffffff !important;
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 0px 10px -2px !important;
  z-index: 20 !important;
}

.ant-layout.side-layout {
  margin: 0px;
}

.sw-side-nav-trigger {
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  width: 30px;
  height: 30px;
}

.side-nav-menu-logo {
  margin-left: 24px;
  height: 100px;
  display: flex;
  // justify-content: center;
  align-items: center;
}

.side-nav-menu-logo-st-text {
  margin-top: 6px;
  margin-bottom: 4px;
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 18px;
  // font-weight: 700;
  color: black;
}

.side-nav-wrapper {
  position: relative;
}

.side-nav-menu-logo-cms-text {
  font-family: 'Roboto Regular';
  margin-top: -4px;
  font-size: 14px;
  min-width: 150px;
  font-weight: 500;
  color: #0a186e;
  line-height: 18px;
}

.side-nav-menu-version-wrapper {
  display: flex;
  align-items: center;
}

.side-nav-menu-logo-version {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #9099a5;
  display: flex;
  align-items: center;
}

.side-nav-menu-beta-version {
  font-family: 'Roboto Regular';
  font-weight: 600;
  font-size: 8px;
  color: #fff;
  background: #34a5fe;
  width: 33px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
  margin-left: 10px;
}

.side-nav-menu-title {
  color: #0a186e;
  height: 60px;
  margin: 20px 0 20px 24px;
  font-size: 16pt;
  font-family: 'Roboto Regular';
  font-weight: 700;
  line-height: 28px;
  display: flex;
  align-items: center;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.side-nav-menu-list {
  border: 0;

  overflow-y: scroll;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */

  > .ant-menu-submenu {
    .ant-menu-submenu-title {
      padding-left: 24px !important;
      margin: 0;
      width: auto;
      font-weight: 400;
      font-size: 14px;
      color: #767b9b;
      .ant-menu-submenu-arrow {
        color: #767b9b;
      }
    }
    .ant-menu-item {
      padding-left: 48px !important;
      margin: 0;
      width: auto;
      a {
        font-weight: 400;
        font-size: 14px;
        color: #767b9b;
      }
    }
    .ant-menu-item::after {
      border: none;
    }
    .ant-menu-item-active {
      background: #ededed !important;
    }
    .ant-menu-item-selected {
      a {
        font-weight: 400;
        font-size: 14px;
        color: #0a186e !important;
      }
      background: #d9ebfc !important;
      border-right: 3px solid #0a186e;
    }
    .ant-menu-sub {
      background-color: #fafafa;
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          padding-left: 48px !important;
        }
        .ant-menu-sub {
          .ant-menu-item {
            background: #eeeeee !important;
            padding-left: 72px !important;
          }
          .ant-menu-item-selected {
            background: #d9ebfc !important;
          }
          .ant-menu-item-active {
            background: #ededed !important;
          }
          .ant-menu-item-active.ant-menu-item-selected {
            background: #d9ebfc !important;
          }
        }
      }

      > .ant-menu-submenu-active {
        > .ant-menu-submenu-title {
          background: #ededed;
        }
      }
    }
  }

  > .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      padding-left: 24px !important;
      margin: 0;
      width: auto;
      font-weight: 400;
      font-size: 14px;
      color: #0a186e;
      .ant-menu-submenu-arrow {
        color: #0a186e;
      }
    }
    .ant-menu-item {
      padding-left: 48px !important;
      margin: 0;
      width: auto;
      a {
        font-weight: 400;
        font-size: 14px;
        color: #767b9b;
      }
    }

    .ant-menu-item::after {
      border: none;
    }
    .ant-menu-item-active {
      background: #ededed !important;
    }
    .ant-menu-item-selected {
      a {
        font-weight: 400;
        font-size: 14px;
        color: #0a186e !important;
      }
      background: #d9ebfc !important;
      // border-right: 3px solid #0A186E;
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding-left: 24px !important;
        margin: 0;
        width: auto;
        font-weight: 500;
        font-size: 14px;
        color: #767b9b;
        .ant-menu-submenu-arrow {
          color: #767b9b;
        }
      }
    }
    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        padding-left: 24px !important;
        margin: 0;
        width: auto;
        font-weight: 400;
        font-size: 14px;
        color: #0a186e;
        .ant-menu-submenu-arrow {
          color: #0a186e;
        }
      }
    }
  }

  > .ant-menu-submenu-active {
    > .ant-menu-submenu-title {
      background: #ededed;
    }
  }

  > .ant-menu-item {
    a {
      color: #767b9b;
    }
    padding-left: 24px !important;
    margin: 0;
    width: auto;
  }

  .ant-menu-item:hover {
    a {
      color: #767b9b;
    }
  }

  > .ant-menu-item::after {
    border: none;
  }

  > .ant-menu-item-active {
    background: #ededed !important;
  }

  > .ant-menu-item-selected {
    background: #d9ebfc !important;
    border-right: 3px solid #0a186e;
    color: #0a186e !important;
    a {
      font-weight: 400;
      font-size: 14px;
      color: #0a186e !important;
    }
  }
}

.side-nav-menu-list::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.side-nav-menu-inner {
  background: #fbfbfb;
  border-radius: 0 24px 24px 24px;
  height: 100vh;
  margin-right: 1px;
}

.side-nav-menu-footer {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #767b9b;
}

.site-layout {
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #f0f0f0 !important;
}

.layout-header-background {
  padding: 0;
  background: #fbfbfb;
  height: 48px;
  position: fixed;
  display: block;
  width: 100%;
  z-index: 10;
}

.content-header {
  padding: 0 !important;
  background: #ffffff !important;
  height: 48px !important;
  box-shadow: 0 4px 2px -2px #ececec !important;
  position: fixed !important;
  display: block !important;
  left: 0 !important;
  width: 100%;
  z-index: 12 !important;
  color: #0a186e !important;

  .content-header-left-side {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
.content-inner {
  // height: calc(100vh - 96px);
  margin: 48px 0 0 0 !important;
  // overflow-y: auto;
  // padding: 10px;
  scrollbar-width: thin;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 5px 0 4px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right:not(.ant-table-has-fix-right)
  .ant-table-container::after {
  box-shadow: inset -5px 0 4px -4px rgba(0, 0, 0, 0.15);
}

.content-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  // position: fixed;
  // left: 0;
  width: 100%;
  bottom: 0;
}

.ant-layout-footer {
  background-color: #f0f0f0 !important;
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #e2e2e2;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #d0d0d0;
  border-radius: 10px;
}

// * {
//   scrollbar-color: #d0d0d0 #e2e2e2 !important;
//   scrollbar-width: thin !important;
// }

.ant-table-scroll-horizontal {
  .ant-table-content::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }
  .ant-table-content::-webkit-scrollbar-track {
    background: #e2e2e2;
    border-radius: 0px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    background-clip: content-box;
  }
  .ant-table-content::-webkit-scrollbar-thumb {
    background: #d0d0d0;
    border-radius: 10px;
    border: 1px solid #b5b5b5;
  }
  .ant-table-content::-webkit-scrollbar-thumb:hover {
    background: #d0d0d0;
    border-radius: 10px;
  }
}

.ant-tooltip.ant-tooltip-placement-top {
  transition: all 0.01s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.login-form-subtitle {
  font-family: 'Roboto Regular';
  font-weight: 400;
  font-size: 24px;
  color: black;
  user-select: none;
}
.login-form-version {
  font-family: 'Roboto Regular';
  font-size: 16px;
  float: right;
  text-align: right;
  color: #c9c9c9;
}
.login-button-text {
  font-family: 'Roboto Regular';
  font-weight: 500;
  font-size: 20px;
  color: white;
}
.login-button-text-outline {
  font-family: 'Roboto Regular';
  font-weight: 500;
  font-size: 20px;
  color: #0a186e;
}
.login-registration-keycloak {
  font-family: 'Roboto Regular';
  font-weight: 500;
  font-size: 20px;
  color: #0a186e;
  display: block;
  margin: 30px 0 20px 0;
  cursor: pointer;
}
.login-registration-guide {
  font-family: 'Roboto Regular';
  font-weight: 500;
  font-size: 20px;
  color: #0a186e;
  display: block;
  margin-top: 7vh;
  margin-bottom: 2vh;
}
.login-footer {
  font-family: 'Roboto Regular';
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #767b9b;
  user-select: none;
}

#register-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#register-wrapper::-webkit-scrollbar {
  display: none;
}

.register-form-title {
  font-size: 36px; //3.3vh
  font-weight: 700;
  color: black;
  user-select: none;
  font-family: 'Samsung Sharp Sans Bold';
}
.register-form-subtitle {
  font-family: 'Roboto Regular';
  font-size: 24px;
  font-weight: 400;
  color: #0a186e;
  user-select: none;
}

// .registration-form-wrapper {
//   height: 70vh;
//   width: 100%;
//   overflow-y: scroll;
// }
// .registration-form-wrapper::-webkit-scrollbar-track {
//   border-radius: 10px;
//   padding: 2px 0;
//   background-color: #d9ebfc;
// }
// .registration-form-wrapper::-webkit-scrollbar {
//   width: 10px;
// }
// .registration-form-wrapper::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background-color: #0a1863;
// }

.header-navigation {
  float: left;
  display: flex;
  align-items: center;
  height: 48px;
  margin-left: 20px;
}
.vertical-line {
  height: 30px;
  border-left: 1px solid #aeaeae;
  margin-right: 20px;
}
.vertical-line-sm {
  height: 16px;
  border-left: 1px solid #aeaeae;
  margin-right: 15px;
  margin-left: 15px;
}
.vertical-line-lg {
  height: 32px;
  border-left: 1px solid #aeaeae;
  margin-right: 15px;
  margin-left: 15px;
}
.header-title {
  font-family: 'Roboto Regular';
  margin-left: 20px;
}
.header-countries {
  color: #000 !important;
  display: flex;
  align-items: center;
}
.header-items {
  float: right;
  display: flex;
  align-items: center;
  height: 48px;
}
.header-support {
  margin-right: 30px;
  text-decoration: underline;
  cursor: pointer;
}
.header-notifications {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-user-info {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}

.action-hover-container {
  width: auto;
  color: white;
  background: #ffffff;
  text-align: center;
  margin-top: -13px;
  z-index: 2000;
  transition: opacity 0.2s ease-in-out;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border: 0.5px solid #b8b8b8;

  .action-hover-item {
    a {
      color: #0a186e;
    }
    width: 50px;
    margin: 0;
    text-align: center;
    color: #0a186e;
    font-weight: bold;
    padding: 3px 0;
    cursor: pointer;
    .anticon {
      font-size: 16px;
    }
  }
  .action-hover-item-left {
    border-radius: 4px 0 0 4px;
  }
  .action-hover-item-right {
    border-radius: 0 4px 4px 0;
  }
  .action-hover-item:hover {
    background: #f5f5f5;
  }
  .action-hover-divider {
    display: block;
    width: 2px;
    height: 20px;
    background: #0a186e;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.sw-page-card {
  border-radius: 20px;
}

.user-list-page-content,
.sw-page-content {
  margin: 0px 24px;
}

.user-list-page-content.sw-page-content {
  box-shadow: none;
  position: relative;
  margin-top: 0;
  z-index: 10;
  margin-right: 24px;

  .sw-page-main {
    .ant-card.sw-page-card {
      border-top-left-radius: 0px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.antd-breadcrumb-wrapper {
  margin: 20px 0 20px 24px;

  .antd-breadcrumb {
    .antd-breadcrumb-item,
    .antd-breadcrumb-item > a,
    .ant-breadcrumb-separator {
      color: #767b9b;
    }
    .antd-breadcrumb-item-active {
      color: #0a186e;
    }
  }
}

.header-avatar {
  display: flex;
  align-items: center;
  margin-right: 10px !important;
}
.header-credential {
  margin-right: 10px !important;
}

.row-cell-see-detail {
  text-align: center;
  cursor: pointer;
  color: #0a186e;
  text-decoration: underline;
}

.modal-600 {
  width: 600px !important;
}

.modal-800 {
  width: 800px !important;
}

.modal-small {
  width: 300px !important;
}

.modal-medium {
  width: 500px !important;
}

.modal-large {
  width: 1000px !important;
}

.modal-service-role .ant-modal-content {
  overflow: hidden;
  border-radius: 24px;
}

.modal-service-role .ant-modal-close {
  color: #ffffff;
}

.modal-service-role .ant-modal-close:hover {
  color: #ffffff;
}

.modal-service-role .ant-modal-header {
  background-color: #0a186e;
}

.modal-service-role .ant-modal-title {
  color: #ffffff;
  font-weight: 900;
}

.popup-description {
  color: #262626;
  font-size: 18px;
}

.popup-modal .ant-modal-content {
  overflow: hidden;
  border-radius: 10px;
}

.popup-modal .ant-modal-header {
  background-color: #0a186e;
}

.popup-modal .ant-modal-title {
  color: #ffffff;
  font-weight: 900;
}

.popup-modal {
  width: 50vw !important;
  height: auto;
}

.table-filter-select {
  .ant-select-selector {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
}

.button-primary {
  background: #0a186e !important;
  border-radius: 6px !important;
  // border: 1px solid #0A186E;
  border: none !important;
  color: #ffffff !important;
}
.button-primary:hover {
  background: #454c7a !important;
  border-radius: 6px !important;
  // border: 1px solid #454C7A;
  border: none !important;
  color: #ffffff !important;
}
.button-primary:disabled {
  background: #f5f5f5 !important;
  border-radius: 6px !important;
  border: 1px solid #b8b8b8 !important;
  color: #b8b8b8 !important;
}

.button-outline-primary {
  border-radius: 6px !important;
  border: 1px solid #0a186e;
  color: #0a186e !important;
}
.button-outline-primary:hover {
  background: #f5f5f5 !important;
  border: 1px solid #0a186e;
}

.button-primary-v2 {
  background: #34a5fe !important;
  border-radius: 6px !important;
  // border: 1px solid #0A186E;
  border: none !important;
  color: #ffffff !important;
}
.button-primary-v2:hover {
  background: #81c2f5 !important;
  border-radius: 6px !important;
  // border: 1px solid #454C7A;
  border: none !important;
  color: #ffffff !important;
}
.button-primary-v2:disabled {
  background: #f5f5f5 !important;
  border-radius: 6px !important;
  border: 1px solid #b8b8b8 !important;
  color: #b8b8b8 !important;
}

.button-secondary {
  background: #ffffff !important;
  color: #0a186e !important;
  border: 1px solid #b8b8b8 !important;
  border-radius: 6px !important;
}
.button-secondary:hover {
  background: #ededed !important;
  color: #0a186e !important;
  border: 1px solid #b8b8b8 !important;
  border-radius: 6px !important;
}
.button-secondary:disabled {
  background: #f5f5f5 !important;
  color: #b8b8b8 !important;
  border: 1px solid #b8b8b8 !important;
  border-radius: 6px !important;
}

.form-advance-search-modal {
  padding: 0;

  .sw-form-field-item {
    margin-bottom: 8px !important;
  }

  .advanced-scrollbar-wrapper {
    width: 100%;
    max-height: 450px;
    overflow: auto;

    .advanced-search-column-wrapper {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .advanced-scrollbar-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  .advanced-scrollbar-wrapper::-webkit-scrollbar-track {
    background: #e2e2e2;
    border-radius: 0px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    background-clip: content-box;
  }

  .advanced-scrollbar-wrapper::-webkit-scrollbar-thumb {
    background: #d0d0d0;
    border-radius: 10px;
    border: 1px solid #b5b5b5;
  }
  .advanced-scrollbar-wrapper::-webkit-scrollbar-thumb:hover {
    background: #d0d0d0;
    border-radius: 10px;
  }
}

.table-info-bottom {
  position: absolute;
  bottom: 20px;
  color: #0a186e;
}
.table-info-bottom-no-pagination {
  margin-top: 20px;
  color: #0a186e;
}

.table-tools {
  .ant-input-affix-wrapper {
    border: 2px solid #0a186e;
    height: 32px !important;
    margin-left: -1px;
  }
  .ant-input-search-button {
    border: 2px solid #0a186e;
    border-radius: 0 5px 5px 0 !important;
    color: #0a186e !important;
  }
  .ant-select-selector {
    border: 2px solid #0a186e !important;
    border-radius: 5px 0 0 5px !important;
  }
  .table-tools-icon {
    margin-left: 10px;
    color: #0a186e;
    font-size: 14px;
    cursor: pointer;
  }
}

.form-title-wrapper {
  margin: 16px 0;
}

.form-divider-anchor {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}

.form-divider-title {
  // font-size: 20px !important;
  font-weight: bold !important;
  color: #0a186e !important;
  background-color: #0a186e;
  margin: 0 0 6px 0 !important;
}

.form-title-text {
  color: #0a186e;
  font-family: 'Inter Regular';
  font-weight: 700;
}

// .form-divider-title::before, .form-divider-title::after {
//   border-top: 1px solid #0A186E !important;
// }

.ant-switch-checked {
  background-color: #0a186e !important;
}

// Disable asterix in the back of required label form field
.ant-form-item-no-colon.ant-form-item-required::before,
.ant-form-item-required::before {
  display: none !important;
}
// Add asterix in the front of required label form field
.ant-form-item-label {
  text-align: left !important;
  font-weight: 400;
  color: #0a186e !important;

  .ant-form-item-no-colon {
    color: #0a186e !important;
  }
  white-space: normal;
  > label {
    display: inline-table;
  }
  > label.ant-form-item-no-colon.ant-form-item-required::after,
  > label.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}
.user-form-history-vertical-line {
  margin: 2px 4px;
  border-left: 1px solid #000;
}
.user-form-history-email {
  font-family: 'Roboto Bold';
  color: #0a186e;
  text-decoration: underline;
}
.add-service-role-button {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.add-service-role-text {
  font-size: 16px;
  font-family: 'Roboto Bold';
  color: #0a186e;
}

.sw-field-input,
.sw-field-textarea {
  border-radius: 5px;
}
.sw-field-select {
  .ant-select-selector {
    border-radius: 5px !important;
  }
}

.custom-center {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-table-row,
.ant-table-thead {
  height: 50px !important;
}

.did-list {
  li {
    margin: 10px 0;
    span {
      font-weight: bold;
      margin-left: 20px;
      a {
        color: #0a186e !important;
      }
    }
  }
}

.did-input {
  .ant-form-item-extra {
    display: flex;
    justify-content: flex-end;
  }
}

.column-selection-scrollbar-wrapper {
  width: 100%;

  .column-selection-search-column-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.column-selection-scrollbar-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
.column-selection-scrollbar-wrapper::-webkit-scrollbar-track {
  background: #e2e2e2;
  border-radius: 0px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  background-clip: content-box;
}
.column-selection-scrollbar-wrapper::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 10px;
  border: 1px solid #b5b5b5;
}
.column-selection-scrollbar-wrapper::-webkit-scrollbar-thumb:hover {
  background: #d0d0d0;
  border-radius: 10px;
}

.bordered-block-text {
  border: 0.5px solid #b8b8b8;
  border-radius: 6px;
  padding: 2px 10px;
}

.bordered-block-copy {
  display: inline-block;

  .text-content {
    border: 0.5px solid #b8b8b8;
    border-radius: 6px 0 0 6px;
    padding: 2px 10px;
  }

  .ant-typography-copy {
    border: 0.5px solid #b8b8b8 !important;
    border-left: none !important;
    border-radius: 0 6px 6px 0 !important;
    padding: 2px 10px !important;
    margin-left: 0 !important;
  }
}

.num-list {
  margin-top: 30px;
}

.num-list::marker {
  font-weight: bold;
}

.ant-back-top {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e4f0ff;
  border-radius: 15px;
  padding: 25px !important;

  .back-top-icon {
    color: #387ddb;
  }
}

.modal-htu-did {
  width: 760px !important;
}

.modal-htu-did .ant-modal-content {
  overflow: hidden;
  border-radius: 24px;
}

.modal-htu-did .ant-modal-body {
  padding: 40px;
}

.modal-htu-did .ant-modal-close {
  color: #ffffff;
}

.modal-htu-did .ant-modal-close:hover {
  color: #ffffff;
}

.modal-htu-did .ant-modal-header {
  background-color: #0a186e;
}

.modal-htu-did .ant-modal-title {
  color: #ffffff;
  font-weight: 900;
}

.ant-upload.ant-upload-select-picture-card {
  background-color: #fff !important;
  border: 0.6px solid #bbbbbb !important;
  border-radius: 7px;
  color: #0a186e;

  &.ant-upload-error {
    border-color: #ff4d4f !important;
  }
}

.ant-form-item-extra {
  color: #767b9b;
}

.header-roles {
  color: #000;
}

.header-country-button {
  border-radius: 6px !important;
  padding: 1px 10px !important;
  height: 24px;
}

.ant-menu-sub.ant-menu-inline {
  background: none;
}

.message-success {
  border-radius: 8px !important;
  border-left: 8px solid #52c41a;

  .ant-notification-notice-message {
    font-weight: bold;
  }

  .ant-notification-notice-icon {
    color: #52c41a;
  }
}

.message-error {
  border-radius: 8px !important;
  border-left: 8px solid #ff4d4f;

  .ant-notification-notice-message {
    font-weight: bold;
  }

  .ant-notification-notice-icon {
    color: #ff4d4f;
  }
}

.message-info {
  border-radius: 8px !important;
  border-left: 8px solid #1890ff;

  .ant-notification-notice-message {
    font-weight: bold;
  }

  .ant-notification-notice-icon {
    color: #1890ff;
  }
}

.message-warning {
  border-radius: 8px !important;
  border-left: 8px solid #faad14;

  .ant-notification-notice-message {
    font-weight: bold;
  }

  .ant-notification-notice-icon {
    color: #faad14;
  }
}

.st-text {
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 40px;
  font-weight: 700;
  color: black;
}

.cms-text {
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 32px;
  font-weight: 700;
  color: #0a186e;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #0a186e;
}

.notes-text {
  color: #0a186e;
  .notes-text-asterix {
    color: #ff4d4f;
  }
}

.notes-text-asterix {
  color: #ff4d4f;
}

.edit-tree-switcher {
  .ant-tree-switcher {
    display: none;
  }
}

.edit-specific-version-extra {
  padding-left: 8px;
  padding-right: 8px;
  color: #767b9b;
}

.edit-specific-form {
  .ant-col-12 {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.icon-table-header {
  display: flex;
  margin-bottom: 0;
  margin-right: 24px;

  .table-tools {
    justify-content: right;
    align-items: center;
    top: 0;
    @media (max-width: 720px) {
      justify-content: left;
    }
  }
}

.icon-content.sw-page-content {
  box-shadow: none;
  position: relative;
  margin-top: 0;
  z-index: 10;

  .sw-page-main {
    .ant-card.sw-page-card {
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 6px 10px;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .ant-table-tbody > tr > td > p {
    margin-bottom: 0;
  }

  .ant-input-group-wrapper .ant-input-search-button {
    height: 32px;
  }

  .ant-input-group-addon .ant-select {
    margin: -5px -11px;
    width: 150px;
  }
  .sw-page-header-detail {
    padding: 0px 0px !important;
  }
  .sw-table-tool-actions {
    // width: 100%;
    display: contents;
  }

  .sw-table-container {
    width: calc(100% + 10px);
  }

  .sw-row-action-cell {
    color: #3057a5;
    font-weight: bold;
  }
  .createNewButton {
    background-color: #01cafe;
    border: none;
  }
  .searchDiv {
    text-align: right;
    height: 34px;
  }
  .tableToolsIcon {
    padding-top: 10px;
  }
  .campaignListButton {
    color: #0197fe;
    background-color: #f1f1f1;
    border: 1px solid #c1c1c1;
    margin-left: 10px;
  }
  .campaignListButton:hover {
    color: #0197fe;
    background-color: #f1f1f1;
    border: 1px solid #0197fe;
    margin-left: 10px;
  }

  .smallFont {
    font-size: 8px;
    margin: 0;
  }

  .ant-table .ant-table-content {
    padding-bottom: 15px;

    .ant-table-cell.ant-table-column-has-sorters {
      padding-bottom: 0 !important;
    }
  }

  .ant-table .layout-preview-card {
    font-size: 8px;
    width: 200px;
    max-width: 200px !important;
    height: 93px !important;
    border-radius: 12px !important;
    margin-top: 0;
    padding: 0;
  }
  .ant-table .ant-card-meta-avatar {
    float: left;
    width: 20px !important;
    height: 20px !important;
    padding-right: 8px !important;
  }
  .ant-table .layout-preview-card .ant-card-meta {
    margin: 5px !important;
  }
  .ant-table .layout-preview-card .ant-avatar.image-preview,
  .ant-table .layout-preview-card .ant-avatar.icon-preview {
    width: 32px !important;
    height: 32px !important;
  }

  .ant-table .layout-preview-card .view-detail {
    margin: 0px 5px 5px 0 !important;
    font-size: 8px !important;
    float: right;
    color: #fff;
  }

  .layout-preview-card .ant-card-body {
    padding: 18px;
  }

  .ant-table .layout-preview-card .meta-content-container {
    height: 52px !important;
    display: flex;
    align-items: center;
  }

  .ant-table .layout-preview-card .subtitle-font-style {
    font-size: 6px !important;
  }

  .ant-table .layout-preview-card .bg-image-text {
    height: 69px !important;
    display: flex;
    align-items: center;
  }

  .static-recommendation-table-tool {
    .sw-table-info {
      visibility: hidden;
      display: none;
      // margin-top: 12px;
    }
    .ant-divider-horizontal {
      display: none;
    }
  }

  .rec-row {
    min-height: 90px;
    .ant-card {
      // margin-top: 10px;
      // margin-bottom: 10px;
      background-color: #fafafa;
      .ant-card-body {
        padding: 10px 5px 5px 5px;
        // padding-top: 15px;
        // padding-right: 10px;
        // padding-bottom: 10px;
        // padding-left: 10px;
      }
    }
    .p {
      margin: auto;
    }
    .preview-recommendation {
      display: flex;
      padding-bottom: 10px;
      .ant-card-body {
        padding: 7px 10px 5px 5px;
      }
      .icon-text-tmplt {
        display: block;
        .ant-card-meta-avatar {
          margin-top: 5px;
          width: 20px;
          height: 20px;
        }

        .ant-card-meta-detail {
          padding: 5px 0 0 20px;
        }
      }

      .image-text-tmplt {
        display: block;
        .ant-card-meta-avatar {
          margin-top: 5px;
        }

        .ant-card-meta-detail {
          padding: 5px 0 0 20px;
        }
      }
      .only-text-tmplt {
        display: block;
        .ant-card-meta-detail {
          padding: 8px 0 0 0px;
        }
        .view-details {
          padding-top: 35px;
        }
      }

      .view-detail {
        margin: 0 5px 5px 0 !important;
        font-size: 8px !important;
        float: right;
        color: #fff;
      }
      .bg-image-text {
        padding: 0;
      }
    }

    // text-align: center;
  }

  .hide-reload .sw-table-tools-buttons .anticon-reload {
    visibility: hidden;
    width: 0px;
  }
  .ant-dropdown-menu-item-active {
    color: #0197fe;
  }
  .ant-dropdown-menu-item-active a {
    color: #0197fe;
  }
  .ant-dropdown-menu-item a span {
    padding-left: 4px;
  }
  .ant-tag {
    margin: 0px;
  }
  .ant-table-thead > tr > th .anticon-filter,
  .ant-table-thead > tr > th .ant-table-filter-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
  .ant-table-filter-dropdown {
    left: 180px !important;
  }
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters {
    padding-left: 30px;
    padding-right: 10px !important;
  }

  // .ant-affix{
  //   .page-header-bottom{
  //     height: 24px;
  //     background: linear-gradient(to right, #f0f0f0 0%, #f0f0f0 23px,  rgba(0,0,0,0)23px, rgba(0,0,0,0) calc(100% - 24px), #f0f0f0 calc(100% - 24px) );
  //     .page-header-curve-bottom{
  //       margin:0 2px;
  //       background: linear-gradient(to right, #fff 0%, #fff 23px,  rgba(0,0,0,0) 23px, rgba(0,0,0,0) calc(100% - 24px), #fff calc(100% - 24px) );
  //       height: inherit;
  //       border-radius: 24px 24px 0px 0px;
  //       box-shadow: 1px -0.5px 1px rgba(0, 0, 0, 0.15);
  //     }
  //   }
  // }

  // .custom-table-filter-gse{
  //   .ant-input-group-wrapper .ant-input-search-button {
  //     height: 42px;
  //   }
  // }

  .sw-page-card {
    border-radius: 0;
    box-shadow: 0px 5px 4px rgba(200, 200, 200, 0.534);
  }

  .sw-page-main {
    .sw-page-card {
      box-shadow: 0px 5px 4px rgba(200, 200, 200, 0.534);
    }
  }
}

.adjusted-table-header {
  th.ant-table-cell {
    vertical-align: top;
  }

  th.ant-table-cell.ant-table-selection-column {
    vertical-align: middle;
  }
}

.icon-status {
  display: flex;
  align-items: flex-end;

  .statusButton {
    background-color: #fff;
    border-radius: 0;
    padding: 0px 1vw;
    border: none;
    box-shadow: 3.1px -1px 6px 1px rgb(200, 198, 198);
    color: #767b9b;
    background-color: #f0f0f0;
  }

  .statusButtonActive {
    background-color: #fff;
    border-radius: 0;
    box-shadow: none;
    padding: 0px 1vw;
    border: none;
    box-shadow: 3.1px -1px 6px 1px rgb(200, 198, 198);
    color: #0a186e;
    z-index: 9;
    background-color: #ffffff;
  }
  // .statusButtonActive:is(:hover, :focus) {
  //   border-radius: 0;
  //   padding: 0px 1.5vw;
  //   border: none;
  //   color: #0A186E;
  //   z-index: 100;
  //   background-color: #d9ebfc;
  // }

  .cornerLeft {
    border-radius: 20px 0px 0px 0px !important;
  }
  .cornerRight {
    border-radius: 0px 20px 0px 0px !important;
  }
}

.decor-status {
  display: flex;
  align-items: flex-end;

  .statusButton {
    background-color: #fff;
    border-radius: 0;
    padding: 0px 1vw;
    border: none;
    box-shadow: 3.1px -1px 6px 1px rgb(200, 198, 198);
    color: #767b9b;
    background-color: #f0f0f0;
  }

  .statusButtonActive {
    background-color: #fff;
    border-radius: 0;
    box-shadow: none;
    padding: 0px 1vw;
    border: none;
    box-shadow: 3.1px -1px 6px 1px rgb(200, 198, 198);
    color: #0a186e;
    z-index: 9;
    background-color: #ffffff;
  }
  // .statusButtonActive:is(:hover, :focus) {
  //   border-radius: 0;
  //   padding: 0px 1.5vw;
  //   border: none;
  //   color: #0A186E;
  //   z-index: 100;
  //   background-color: #d9ebfc;
  // }

  .cornerLeft {
    border-radius: 20px 0px 0px 0px !important;
  }
  .cornerRight {
    border-radius: 0px 20px 0px 0px !important;
  }
}

.campaign-tab {
  .cornerLeft {
    border-radius: 20px 0px 0px 0px !important;
  }
  .cornerRight {
    border-radius: 0px 20px 0px 0px !important;
  }
}

.custom-error-bg {
  background-color: #fffedc;
  border-radius: 10px;
}
.custom-error-font {
  color: #fc0000;
  font-weight: bold;
}
.error-list {
  color: #fc0000;
  list-style-type: none;
  list-style-image: url(../assets/icon-bullets.svg);
}
.local-select {
  width: 250px;

  .ant-select-selector {
    border: 1px solid rgb(118, 123, 155);
    border-radius: 6px !important;
  }

  .ant-select-arrow {
    color: #0a186e;
  }
}

.routine-form-card {
  .ant-card-body {
    padding-bottom: 80px !important;
  }
}

.about-card {
  margin: 0px;
}
.about-feature-card {
  margin: 0px;
  .sw-page-card {
    display: flex;
    height: 100%;
  }
  .feature-header {
    font-family: 'Samsung Sharp Sans Bold';
    font-weight: 600;
    font-size: 24px;
    margin: 24px 0;
  }
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  height: 24px;
  padding: 0;
}

.ant-menu-item-only-child {
  margin-bottom: 0 !important;
}

.stcms-dark-card {
  .ant-card {
    background-color: #313131;
    color: #fff;
  }
}

.stcms-new {
  font-family: 'Samsung Sharp Sans Bold';
  font-weight: 600;
  font-size: 24px;
  color: #fff;
}

.stcms-desc {
  font-family: 'Samsung Sharp Sans Bold';
  font-weight: 600;
  .stcms-st {
    font-size: 24px;
    color: #000;
  }
  .stcms-cms {
    font-size: 20px;
    color: #0a186e;
  }
}

.stcms-features {
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 24px;
  color: #313131;
}
.curr-active-row {
  td {
    background-color: #eef7ff !important;
  }
}

.ant-card.country-form-container,
.country-form-container {
  max-height: 500px;
  margin-top: 16px;
  margin-bottom: 32px;
  padding: 0;
  border-radius: 4px;
  overflow-y: auto;
  width: 100%;
  &.ant-card-bordered.error {
    border: 1px solid #f5222d;
  }
  &.ant-card-bordered.hidden {
    border: none;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ant-card-body {
    padding: 0;
  }
  &.extra-height {
    .country-text-row {
      height: 304px;
    }
    // disable grammarly extension for JSON field data
    grammarly-extension {
      display: none;
    }
  }
  .country-text-row {
    height: 204px;
    overflow-y: auto;
    &:not(:last-child) {
      padding-bottom: 16px;
    }
    .country-field-container {
      .ant-form-item-control {
        min-height: 54px;
      }
      .ant-row.ant-form-item {
        margin-bottom: 0;
        min-height: 54px;
        padding-bottom: 8px;
        .ant-input-group {
          margin-bottom: 4px;
        }
        input.ant-input {
          margin-bottom: -1px;
        }
        &.ant-form-item-with-help {
          margin-bottom: 0;
          .ant-form-explain {
            margin-top: -4px;
            margin-bottom: 0;
          }
        }
      }
    }
    .anticon-delete {
      padding-right: 10px;
      cursor: pointer;
      color: @error-color;
    }
  }
}
.country-form-container .ant-card-body {
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 100%;
  padding: 0px;
}
.country-list-container {
  width: 100%;
  overflow-y: auto;
  height: 304px;
  border-right: 1px solid #e8e8e8;
  .list {
    padding: 0;
    margin: 0;
    .list-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 56px;
      border-bottom: 1px solid #e8e8e8;
      &:hover {
        background: #f9f9f9;
      }
      &.active,
      &:hover.active {
        background: #e8e8e8;
      }
      &.error {
        background: @error-color-lighter;
        &:hover {
          background: @error-color-light;
        }
        &.active,
        &:hover.active {
          background: @error-color-light;
        }
      }
      &.error.info {
        .country-name {
          width: 75%;
        }
        .action-container {
          width: 35%;
          .anticon:not(:last-child) {
            padding-right: 3px;
          }
        }
      }
      .country-name {
        width: 80%;
      }
      .action-container {
        width: 20%;
        text-align: right;
        .anticon-delete {
          color: @error-color;
        }
      }
    }
  }
}

.country-text-row {
  height: 204px;
  overflow-y: auto;
}
.country-field-container {
  padding: 10px 10px 10px 40px;
}
.country-field-container .ant-row.ant-form-item {
  margin-bottom: 0;
  min-height: 54px;
  padding-bottom: 0px;
}
.country-field-container .ant-form-item-control {
  min-height: 54px;
}

.tag-detail {
  border-radius: 6px;
  width: 50px;
  text-align: center;
  border: solid 1px #b8b8b8;
  color: #0a186e;
  margin: 2px;
  font-size: 14px;
  padding: 2px;
  cursor: pointer;
}

.tag-detail-md {
  border-radius: 6px;
  width: 80px;
  text-align: center;
  border: solid 1px #b8b8b8;
  color: #0a186e;
  margin: 2px;
  font-size: 14px;
  padding: 2px;
  cursor: pointer;
}

.tag-detail-lg {
  border-radius: 6px !important;
  width: 100px;
  text-align: center;
  border: solid 1px #b8b8b8 !important;
  color: #0a186e !important;
  margin: 2px !important;
  font-size: 14px !important;
  padding: 2px !important;
  cursor: pointer;
}

.notification-menu {
  width: 680px !important;
  padding: 20px !important;
  border-radius: 10px !important;
}
.notification-header {
  font-size: 18px;
  font-family: 'Roboto Regular';
  font-weight: 500;
  color: #0a186e;
}
.notification-date {
  font-size: 18px;
  font-family: 'Roboto Regular';
  font-weight: 400;
}
.notification-message {
  margin-bottom: 20px;
  .message-title {
    font-size: 18px;
    font-family: 'Roboto Regular';
    font-weight: 500;
    margin-bottom: 20px;
  }
  .message-desc {
    font-size: 16px;
    font-family: 'Roboto Regular';
    font-weight: 400;
  }
}
.notification-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.release-header {
  font-size: 18px;
  font-family: 'Roboto Regular';
  font-weight: 500;
  color: #0a186e;
}
.release-tabs {
  .ant-tabs-nav {
    .ant-tabs-nav-operations {
      display: none;
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        height: calc(100vh - 300px);
        overflow: auto;
      }
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          width: 140px;
          height: 36px;
        }
      }
    }
  }

  .ant-tabs-content-holder {
    height: calc(100vh - 300px);
    overflow: auto;
  }

  .ant-tabs-content {
    padding-left: 40px;
    .ant-tabs-tabpane {
      padding-left: 0 !important;
    }
  }
}

.sitemap-menu {
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    border-radius: 10px;
  }
  .ant-modal-title {
    font-weight: 700;
    color: #000;
    font-size: 16px;
  }
  .sitemap-header {
    font-weight: 700;
    color: #0a186e;
  }
  .sitemap-sec-header {
    font-weight: 700;
    color: #000;
  }
  .sitemap-child-1 {
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .sitemap-child-2 {
    padding-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.pointer {
  cursor: pointer;
}

.credential-menu {
  border-radius: 10px !important;
}

.sitemap-button {
  height: 30px;
  color: #000 !important;
  border: 1px solid #b8b8b8 !important;
  border-radius: 8px !important;
  margin-right: 15px;
}

.sitemap-button:hover {
  color: #40a9ff !important;
  border: 1px solid #b8b8b8 !important;
}

.sitemap-button:active {
  color: #40a9ff !important;
  border: 1px solid #b8b8b8 !important;
}

.header-search {
  width: 250px;
  .ant-select-selector {
    border-radius: 8px;
    height: 30px !important;
    border: 1px solid #b8b8b8 !important;
  }
}

.user-manager-table-header {
  display: flex;
  margin-bottom: 0;
  margin-right: 24px;

  .user-manager-status {
    display: flex;
    align-items: flex-end;

    .statusButton {
      background-color: #fff;
      border-radius: 0;
      padding: 0px 1vw;
      border: none;
      box-shadow: 3.1px -1px 6px 1px rgb(200, 198, 198);
      color: #767b9b;
      background-color: #f0f0f0;
    }

    .statusButtonActive {
      background-color: #fff;
      border-radius: 0;
      box-shadow: none;
      padding: 0px 1vw;
      border: none;
      box-shadow: 3.1px -1px 6px 1px rgb(200, 198, 198);
      color: #0a186e;
      z-index: 9;
      background-color: #ffffff;
    }

    .cornerLeft {
      border-radius: 20px 0px 0px 0px !important;
    }
    .cornerRight {
      border-radius: 0px 20px 0px 0px !important;
    }
  }
  .notif-manager-table-tools {
    justify-content: right;
    align-items: center;
    display: flex;
    top: 0;
  }
}

.notif-manager-table-header {
  display: flex;
  margin-bottom: 0;
  margin-right: 24px;

  .notif-manager-status {
    display: flex;
    align-items: flex-end;

    .statusButton {
      background-color: #fff;
      border-radius: 0;
      padding: 0px 1vw;
      border: none;
      box-shadow: 3.1px -1px 6px 1px rgb(200, 198, 198);
      color: #767b9b;
      background-color: #f0f0f0;
    }

    .statusButtonActive {
      background-color: #fff;
      border-radius: 0;
      box-shadow: none;
      padding: 0px 1vw;
      border: none;
      box-shadow: 3.1px -1px 6px 1px rgb(200, 198, 198);
      color: #0a186e;
      z-index: 9;
      background-color: #ffffff;
    }

    .cornerLeft {
      border-radius: 20px 0px 0px 0px !important;
    }
    .cornerRight {
      border-radius: 0px 20px 0px 0px !important;
    }
  }
  .notif-manager-table-tools {
    justify-content: right;
    align-items: center;
    display: flex;
    top: 0;
  }
}

.notif-manager-content.sw-page-content {
  box-shadow: none;
  position: relative;
  margin-top: 0;
  z-index: 10;
  margin-right: 12px;

  .sw-page-main {
    .ant-card.sw-page-card {
      border-top-left-radius: 0px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.decor-map-content.sw-page-content {
  box-shadow: none;
  position: relative;
  margin-top: 0;
  z-index: 10;
  margin-right: 12px;

  .sw-page-main {
    .ant-card.sw-page-card {
      border-top-left-radius: 0px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.decor-map-ctg-content.sw-page-content {
  box-shadow: none;
  position: relative;
  margin-top: 0;
  z-index: 10;
  margin-right: 12px;

  .sw-page-main {
    .ant-card.sw-page-card {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.notif-manager-preview {
  margin-left: 0;
}

.release-page-content {
  .ant-card-body {
    padding: 0;
  }

  .ant-tabs-tab {
    padding: 8px 28px 8px 48px !important;
  }

  .ant-tabs-tab-active {
    background-color: #e6f0fe;
  }

  .collapse-year {
    font-weight: bold;
    color: #0a186e;
    margin-left: -20px;
    img {
      margin-left: 50px;
    }
  }

  .release-tabs {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #0a186e;
    }
  }
  .release-tabs
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab {
    width: auto;
    margin-top: 0;
  }
}

.role-info {
  .header {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: #0a186e;
    padding: 10px 10px 10px 30px;
  }

  .sub-header {
    width: 100%;
    padding: 10px 10px 10px 45px;
    background: #fafafa;
    cursor: pointer;
  }

  .sub-header:hover {
    background: #ededed;
  }

  .sub-header-active {
    width: 100%;
    padding: 10px 10px 10px 45px;
    background: #e6f0fe;
    border-right: 2px solid #0a186e;
    color: #0a186e;
    font-weight: bold;
    cursor: pointer;
  }
}

.user-info {
  .header {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: #0a186e;
    padding: 10px 10px 10px 30px;
  }

  .sub-header {
    width: 100%;
    padding: 10px 10px 10px 45px;
    background: #fafafa;
    cursor: pointer;
  }

  .sub-header:hover {
    background: #ededed;
  }

  .sub-header-active {
    width: 100%;
    padding: 10px 10px 10px 45px;
    background: #e6f0fe;
    border-right: 2px solid #0a186e;
    color: #0a186e;
    font-weight: bold;
    cursor: pointer;
  }
}

.notif-preview-header {
  font-family: 'Roboto Regular';
  font-size: 18px;
  font-weight: bold;
}

.notif-label {
  font-weight: bold;
  color: #0a186e;
}

.modal-guide {
  .ant-modal-body {
    padding: 0;
  }
}

.top {
  vertical-align: top;
}

.elipsis-text {
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.custom-input-text,
.custom-input-text:focus-visible,
.custom-input-text:focus,
.custom-input-text:active {
  outline: none;
}

.custom-input-text::placeholder {
  color: #bfbfbf;
}

.custom-input-wrapper {
  border-radius: 5px !important;
  border: 1px solid #d9d9d9 !important;
  display: flex;
}

.custom-input-wrapper:hover {
  border-radius: 5px !important;
  border: 1px solid #40a9ff !important;
  display: flex;
}

.ant-select-selector {
  border-radius: 5px !important;
}
.general-field-placeholder {
  font-size: 13px !important;
  color: #a2a5bb !important;
  display: flex;
  font-family: 'Inter Regular' !important;
  height: inherit;
  align-items: center;
  justify-content: center;
  padding: 5px 10px 5px 10px;
}

.placeholder {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 0.6px dashed #767b9b;
  background-color: white;
}

.avatar-placeholder {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 100%;
  border: 0.6px dashed #767b9b;
  background-color: white;
}

.preview_content {
  .ant-card-bordered {
    border-radius: 5px;
    background-color: #fbfbfb;
  }
}

.preview_button {
  color: #ffffff !important;
  width: auto;
}

.type-badge {
  background: #ecf4f7;
  color: #047fc5;
  padding: 4px 12px 4px 12px;
  border-radius: 100px;
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-filter-select {
  .ant-select-selector {
    font-weight: normal !important;
    border: 1px solid #d9d9d9 !important;
  }
}

.ant-popover-inner {
  border-radius: 7px;
}

.ant-popover-title {
  color: #fff;
  background-color: #0a186e;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.input-search-did {
  .ant-btn.ant-btn-icon-only.ant-input-search-button {
    border-color: #0a186e;
  }
}

.custom-error-field {
  margin-left: 24px;
  margin-bottom: 24px;
  margin-right: 24px;
  background-color: #fffedc;
  border-radius: 10px;
}

.ant-table-cell {
  color: #0a186e !important;
}

.modal-button {
  width: 80px;
}
.action-hover-container .action-hover-item .anticon {
  font-size: 24px;
}

.request-note {
  .ant-form-item-extra {
    text-align: end;
  }
}

.service-panel {
  .ant-collapse-header {
    display: flex;
    align-items: center !important;
    border-radius: 6px;
  }
}

